import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AgGridAngular} from 'ag-grid-angular';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {CrudOverviewButtonConfig} from '../crud-overview-data/interfaces/crud-overview-button-config.interface';
import {CrudOverviewDataConfig} from '../crud-overview-data/interfaces/crud-overview-data-config.interface';
import {CrudOverviewExtraActionConfig} from '../crud-overview-data/interfaces/crud-overview-extra-action-config.interface';
import {GridSelectionExtraActionsDialogComponent} from './grid-selection-extra-actions-dialog/grid-selection-extra-actions-dialog.component';

@Component({
  selector: 'vdw-grid-selection-options',
  templateUrl: './grid-selection-options.component.html',
  styleUrls: ['./grid-selection-options.component.scss']
})
export class GridSelectionOptionsComponent implements OnInit {
  public hasCustomActions: boolean;

  public extraOptionsComponent = GridSelectionExtraActionsDialogComponent;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: {grid: AgGridAngular; config: CrudOverviewDataConfig<any>},
    private readonly dialogRef: MatDialogRef<GridSelectionOptionsComponent>
  ) {}

  public get selectedNodes(): number {
    const selectedRows = this.data.grid?.api?.getSelectedRows()?.length ?? 0;

    return Math.max(1, selectedRows);
  }

  public get buttons(): CrudOverviewButtonConfig[] {
    return this.data.config.buttons;
  }

  public get grid(): AgGridAngular {
    return this.data.grid;
  }

  public get extraActions(): CrudOverviewExtraActionConfig[] {
    return this.data.config.extraActions;
  }

  public get onlyShowSelected(): boolean {
    return this.data.config.onlyShowSelected;
  }

  public ngOnInit(): void {
    this.hasCustomActions = this.data.config.extraActions.length !== 0;
  }

  public hasClickEvent(button: CrudOverviewButtonConfig): boolean {
    return !AssertionUtils.isNullOrUndefined(button.onClick);
  }

  public toggleShowOnlySelected(checked: boolean): void {
    this.data.config.onlyShowSelected = checked;
    if (this.data.config.gridOptions.rowModelType === 'serverSide') {
      this.data.grid.api.onFilterChanged();
    } else {
      const data = AssertionUtils.isNullOrUndefined(this.data.config.rowData) ? this.data.config.gridOptions.rowData : this.data.config.rowData;
      checked ? this.data.grid.api.setGridOption('rowData', this.data.grid?.api?.getSelectedRows()) : this.data.grid.api.setGridOption('rowData', data);
    }
  }

  public deselect(): void {
    this.data.grid?.api.deselectAll();
    this.toggleShowOnlySelected(false);

    this.dialogRef.close();
  }
}

import {Injectable} from '@angular/core';
import {finishingTemplateDeficitLengthComparator} from '@application/helper/finishing-template-deficit-length-comparator';
import {GridCompatibleWithMachineQualityComponent} from '@presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/grid-compatible-with-machine-quality/grid-compatible-with-machine-quality.component';
import {GridCuttingLineComponent} from '@presentation/pages/textile-data/textile-data-overview/grid-cutting-line/grid-cutting-line.component';
import {GridLabelsComponent} from '@presentation/pages/textile-data/textile-data-overview/grid-labels/grid-labels.component';
import {ColDefBuilderFactoryService, LinkIconRendererComponent, StringUtils, TranslateService} from '@vdw/angular-component-library';
import {ColDef, ValueGetterParams} from 'ag-grid-community';

@Injectable()
export class OverviewListFinishingAndFinishingTemplateService {
  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(isTemplate: boolean, isSelection: boolean, nameCellRendererParams?: any): ColDef[] {
    const nameColDefBuilder = this.colDefBuilderFactoryService
      .getBuilder()
      .withHeaderName('GENERAL.NAME')
      .withField(isTemplate ? 'name' : 'nameWithVersion', true)
      .withComparator(StringUtils.stringComparator)
      .withLockVisible();

    if (nameCellRendererParams) {
      nameColDefBuilder.withCellRenderer(LinkIconRendererComponent, nameCellRendererParams);
    }

    const columnDefs = [
      nameColDefBuilder.build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.TYPE')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`TEXTILE_DATA.FINISHING_TEMPLATE.FINISHINGTYPE_OPTIONS.${params.data.finishingType}`), true)
        .withComparator(StringUtils.stringComparator)
        .withLockVisible()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_BEFORE', 1, false, 'GENERAL.UNIT.MILLIMETER')
        .withField('cutbarsInformation.cutbarBeforeProperties.commercialLengthInMM', true)
        .withValueGetter((params: ValueGetterParams) => params.data.cutbarsInformation?.cutbarBeforeProperties?.commercialLengthInMM?.toFixed())
        .withNumericMultiFilter()
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_AFTER', 1, false, 'GENERAL.UNIT.MILLIMETER')
        .withField('cutbarsInformation.cutbarAfterProperties.commercialLengthInMM', true)
        .withValueGetter((params: ValueGetterParams) => params.data.cutbarsInformation?.cutbarAfterProperties?.commercialLengthInMM?.toFixed())
        .withNumericMultiFilter()
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTTING_LINE.CUTTING')
        .withField('cutbarsInformation.cuttingLineProperties')
        .withCellRenderer(GridCuttingLineComponent)
        .withFilterValueGetter((params: ValueGetterParams) => this.translate.instant(params.data.cutbarsInformation?.cuttingLineProperties ? 'GENERAL.CONDITION.YES' : 'GENERAL.CONDITION.NO'))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.FOLDING_CORRECTION')
        .withField('cutbarsInformation.foldingType.name', true)
        .withValueGetter((params: ValueGetterParams) => {
          return params.data.cutbarsInformation?.foldingType?.id > 0 ? 'Yes' : 'No';
        })
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.LABEL.LABEL')
        .withField('labels')
        .withCellRenderer(GridLabelsComponent)
        .withFilterValueGetter((params: ValueGetterParams) => this.translate.instant(params.data.labels.length ? 'GENERAL.CONDITION.YES' : 'GENERAL.CONDITION.NO'))
        .build()
    ];

    if (isTemplate) {
      if (isSelection) {
        columnDefs.splice(5, 1);
        columnDefs.push(
          this.colDefBuilderFactoryService
            .getBuilder()
            .withHeaderName('TEXTILE_DATA.FINISHING.SELECTION_OF_FINISHING_TEMPLATE.COMPATIBLE_WITH_MACHINE_QUALITY')
            .withCellRenderer(GridCompatibleWithMachineQualityComponent)
            .withComparator(finishingTemplateDeficitLengthComparator)
            .withoutFilter()
            .build()
        );
      }
    } else {
      columnDefs.splice(
        4,
        0,
        this.colDefBuilderFactoryService
          .getBuilder()
          .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_BEFORE', 1, false, 'GENERAL.UNIT.PICKS')
          .withField('cutbarsInformation.cutbarBeforeProperties.technicalLengthInPicks', true)
          .withNumericMultiFilter()
          .withRightAlignment()
          .build(),
        this.colDefBuilderFactoryService
          .getBuilder()
          .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_AFTER', 1, false, 'GENERAL.UNIT.PICKS')
          .withField('cutbarsInformation.cutbarAfterProperties.technicalLengthInPicks', true)
          .withNumericMultiFilter()
          .withRightAlignment()
          .build()
      );
    }

    return columnDefs;
  }
}

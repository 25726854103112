import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AgGridModule} from 'ag-grid-angular';
import {DialogModule} from '../../dialogs/dialog.module';
import {TranslationModule} from '../../translation/translation.module';
import {GridSelectionsOptionsModule} from '../grid-selection-options/grid-selection-options.module';
import {SlideToggleModule} from '../slide-toggle/slide-toggle.module';
import {CrudGridContextService} from './crud-grid-context-service/crud-grid.context.service';
import {CrudGridWithActionsComponent} from './crud-grid-with-actions/crud-grid-with-actions.component';
import {CrudObjectSelectionDialogComponent} from './crud-object-selection-dialog/crud-object-selection-dialog.component';
import {CrudOverviewDataPageComponent} from './crud-overview-data-page/crud-overview-data-page.component';

@NgModule({
  imports: [CommonModule, AgGridModule, MatIconModule, MatButtonModule, MatTooltipModule, DialogModule, SlideToggleModule, GridSelectionsOptionsModule, TranslationModule],
  declarations: [CrudGridWithActionsComponent, CrudObjectSelectionDialogComponent, CrudOverviewDataPageComponent],
  providers: [CrudGridContextService],
  exports: [CrudOverviewDataPageComponent, CrudObjectSelectionDialogComponent]
})
export class CrudOverviewDataModule {}

// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "26521d92bb-dirty",
    "hash": "26521d92bb",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "26521d92bb-dirty",
    "semverString": null,
    "version": "5.34.1"
};
